require("@hotwired/turbo-rails");
require("@rails/ujs").start();
require("@rails/activestorage").start();
require("channels");
import "controllers";
import "bootstrap";
window.jQuery = $;
window.$ = $;
$(document).on("turbo:load", () => {
  console.log("turbo!");
  $('[data-toggle="tooltip"]').tooltip();
  $('[data-toggle="popover"]').popover({
    html: true,
  });
});

import { Controller } from "stimulus";

export default class extends Controller {
  connect() {
    console.log("Clear Search");
  }
  clear(e) {
    e.preventDefault();
    console.log("Cleared");
    let fields = document.getElementsByClassName("search-field");
    for (var i = 0; fields.length > i; i++) {
      console.log("Field", fields[i]);
      fields[i].value = "";
      e.target.closest("form").submit();
    }
  }
}

import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["uploadfields", "uploadarea"];
  connect() {}
  selected(e) {
    this.showHideUpload(e.target);
  }
  showHideUpload = (e) => {
    let selected_index = e.selectedIndex;
    let content = this.uploadfieldsTarget.innerHTML;

    if (selected_index === 7) {
      console.log("Agreement upload");
      this.uploadareaTarget.insertAdjacentHTML("beforebegin", content);
    } else if (selected_index === 8) {
      console.log("Court Order Upload");
      this.uploadareaTarget.insertAdjacentHTML("beforebegin", content);
    }
  };
}

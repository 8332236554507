import { Controller } from "stimulus";

export default class extends Controller {
  connect() {}

  selected = (e) => {
    let full_name = e.target.options[e.target.selectedIndex].text;
    let last_name_field = document.getElementsByClassName("d-last-name")[0];
    let first_name_field = document.getElementsByClassName("d-first-name")[0];
    let other_name_field = document.getElementsByClassName("d-other-name")[0];
    if (full_name === "Select individual") {
      last_name_field.value = "";
      first_name_field.value = "";
      return;
    }
    let first_name = full_name.split(",")[1];
    let last_name = full_name.split(",")[0];

    last_name_field.value = last_name;
    first_name_field.value = first_name;
    other_name_field.value = "";
  };
  clearSelector = () => {
    let hidden = document.getElementsByClassName("d-hidden")[0];
    let selector = document.getElementsByClassName("d-selector")[0];
    selector.selectedIndex = -1;
    hidden.value = "";
  };
}

import { Controller } from "stimulus";

export default class extends Controller {
  connect() {
    let selected = document
      .getElementById("clientTypeSelector")
      .value.toLowerCase();
    showCorrectFields(selected);
    displayDeceasedBanner();
  }

  clientTypeSelect(e) {
    let selected = e.target.value.toLowerCase();
    showCorrectFields(selected);
  }

  deceasedDateSet(e) {
    if (isDate(e.target.value)) {
      let check = (document.getElementById("deceasedCheckbox").checked = true);
      console.log("CHECK: ", check);
    } else {
      document.getElementById("deceasedCheckbox").checked = false;
    }
    displayDeceasedBanner();
  }

  selectExisting(e) {
    var form = document.getElementById("client-form");
    fetch(`/selected_existing/${e.target.value}`)
      .then((data) => data.text())
      .then((html) => (form.innerHTML = html));
  }
}

const displayDeceasedBanner = function () {
  if (document.getElementById("dateOfDeath")) {
    var date_field = document.getElementById("dateOfDeath");
    const button = document.getElementById("deceasedButton");
    if (isDate(date_field.value)) {
      document.getElementById("deceasedBanner").classList.remove("hidden");
      button.innerText = "Date of death";
    } else {
      document.getElementById("deceasedBanner").classList.add("hidden");
      button.innerText = "Mark as deceased";
    }
  }
};
const isDate = function (date) {
  return new Date(date) !== "Invalid Date" && !isNaN(new Date(date));
};
const showCorrectFields = function (selected) {
  let all_fields = document.getElementsByClassName("client-form-fields");
  let selected_element = document.getElementById(selected);
  console.log("SELECTED", selected_element);
  var i = 0;
  for (i = 0; i < all_fields.length; i++) {
    let element = all_fields[i];
    console.log("SELCTED:", !selected == true);
    console.log("IS IT IT ", element.id === selected);
    if (!selected == false && element.id !== selected) {
      console.log("REMOVING", element);
      element.remove();
      showCorrectFields(selected);
    }
  }
  document.getElementById(selected).classList.remove("hidden");
};

import { get } from "jquery";
import { Controller } from "stimulus";

export default class extends Controller {
  connect() {
    console.log("IM TRIGGERED!");
  }

  remove(e) {
    let status_selector = document.getElementById("spousal-status-selector");
    let spouse_selector = document.getElementById("spouse-selector");
    let spouse_selector_wrapper = document.getElementById(
      "spouse-selector-wrapper"
    );
    let married_radio = document.getElementById(
      "individual_marital_status_married"
    );
    let not_married_radio = document.getElementById(
      "individual_marital_status_not_married"
    );
    console.log("SELECTOR", status_selector);
    married_radio.checked = false;
    not_married_radio.checked = true;
    spouse_selector_wrapper.classList.add("hidden");
    spouse_selector.value = "";
    console.log("REMOVING");
  }

  change(e) {
    console.log("VCHANGE", e.target.value);
    if (e.target.value === "Not Married") {
      console.log("GONIG TO NOT MARRIED");
      this.remove();
      document.getElementById("remove-spouse-link").click();
    }
  }
}

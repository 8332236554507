import { Controller } from "stimulus";
// This controller makes a group of check boxes act like radio buttons by unchecking all
// in a group when one is checked. It requires all checkbox input elements to have the same
// class last in its class list. This can be achived by adding input_html: {class: "my-class"}
// to a f.input element.

export default class extends Controller {
  connect() {
    console.log("only one!");
  }

  changed = (e) => {
    console.log("checked", e.target.checked);
    if (e.target.checked == true) {
      let class_list = e.target.classList;
      let grouped_class = class_list[class_list.length - 1];
      let group = document.getElementsByClassName(grouped_class);
      for (var i = 0; i < group.length; i++) {
        group[i].checked = false;
      }
      e.target.checked = true;
    }
  };
}

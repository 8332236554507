import { Controller } from "stimulus";

export default class extends Controller {
  connect() {
    console.log("RATE CONTROLLER");
    let user_id = document.getElementById("time_entry_user_id").value;
    this.setRate(user_id);
  }
  newUser = (e) => {
    console.log(e.target.value);
    this.setRate(e.target.value);
  };

  setRate = (user_id) => {
    fetch(`/users_admin/${user_id}.json`)
      .then((response) => response.json())
      .then((json) => {
        document.getElementById("time_entry_rate").value = json.rate;
      });
  };
}

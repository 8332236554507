import { Controller } from "stimulus";

export default class extends Controller {
  connect() {
    let inv_id_field = document.getElementById("time_entry_invoice_id");
  }

  changed(e) {
    console.log("CHANGED", e.target.checked);
    let inv_id_field = document.getElementById("time_entry_invoice_id");
    if (!e.target.checked) {
      inv_id_field.value = null;
    }
  }
}

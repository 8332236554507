import { type } from "jquery";
import { Controller } from "stimulus";

export default class extends Controller {
  connect() {
    let reveal_setters = document.querySelectorAll(
      "input[data-action*='reveal#reveal']"
    );
    for (var i = 0; i < reveal_setters.length; i++) {
      let element = reveal_setters[i];
      let class_name_to_toggle = element.dataset.reveal;
      if (element.checked) {
        DisplayOrHide(class_name_to_toggle, element.value);
      }
    }
    let no_reveal_setters = document.querySelectorAll(
      "input[data-action*='reveal#noReveal']"
    );
    for (var i = 0; i < no_reveal_setters.length; i++) {
      let element = no_reveal_setters[i];
      let class_name_to_toggle = element.dataset.noreveal;
      let reveal = element.value == "true" ? "false" : "true";
      if (element.checked) {
        DisplayOrHide(class_name_to_toggle, reveal);
      }
      class_name_to_toggle = element.dataset.reveal;
      if (element.checked) {
        DisplayOrHide(class_name_to_toggle, element.value);
      }
    }
    let cond_rev_setters = document.querySelectorAll(
      "[data-action*='reveal#conditionalReveal']"
    );

    for (var i = 0; i < cond_rev_setters.length; i++) {
      let element = cond_rev_setters[i];

      let class_name_to_toggle = element.dataset.reveal;
      let match_select = element.dataset.match;
      let selected = element.value;
      let reveal = match_select == selected;
      DisplayOrHide(class_name_to_toggle, String(reveal));
    }
    let multi_cond_rev_setters = document.querySelectorAll(
      "select[data-action*='reveal#multiConditionalReveal']"
    );
    for (var i = 0; i < multi_cond_rev_setters.length; i++) {
      let element = multi_cond_rev_setters[i];
      let class_name_to_toggle = element.dataset.reveal;
      let reveal = element.value.toLowerCase().replace(" ", "-");
      MultiDisplayOrHide(class_name_to_toggle, reveal);
    }
    this.checkboxsetConditionalReveal(0);
  }
  reveal(e) {
    console.log("REVEAL", e);
    let class_name_to_toggle = e.target.dataset.reveal;
    let reveal = e.target.value;
    DisplayOrHide(class_name_to_toggle, reveal);
  }

  noReveal(e) {
    console.log("IN NO REVEAL", e.target.value);
    let class_name_to_toggle = e.target.dataset.noreveal;
    let reveal = e.target.value == "true" ? "false" : "true";
    console.log("VALUE", class_name_to_toggle);
    DisplayOrHide(class_name_to_toggle, reveal);
    this.reveal(e);
  }

  conditionalReveal(e) {
    console.log("IN REVEAL", e.target.value);
    let class_name_to_toggle = e.target.dataset.reveal;
    let match_select = e.target.dataset.match;
    let selected = e.target.value;
    let reveal = match_select == selected;

    DisplayOrHide(class_name_to_toggle, String(reveal));
  }

  multiConditionalReveal(e) {
    console.log("COND REVEAL", e);
    let class_name_to_toggle = e.target.dataset.reveal;
    let reveal = e.target.value.toLowerCase().replace(" ", "-");
    console.log("REVEAL", reveal);
    MultiDisplayOrHide(class_name_to_toggle, reveal);
  }

  checkboxsetConditionalReveal(e) {
    let checkboxset =
      e !== 0
        ? e.target
        : document.querySelectorAll(
            "[data-action*='reveal#checkboxsetConditionalReveal']"
          )[0];
    console.log("CHECKBOXSET", checkboxset);
    let checkboxes = document.getElementsByClassName("source-of-funds");
    let checkbox_array = [].slice.call(checkboxes);
    console.log("IS", checkbox_array);
    let is_reveal_checked = checkbox_array.find(
      (i) => i.value === checkboxset.dataset.match
    ).checked;
    if (is_reveal_checked) {
      this.element
        .getElementsByClassName(checkboxset.dataset.reveal)[0]
        .classList.remove("hidden");
    } else {
      let other_field = document.getElementsByClassName(
        checkboxset.dataset.reveal
      )[0];
      other_field.classList.add("hidden");
      other_field.getElementsByTagName("input")[0].value = "";
    }
    console.log("Check boxes", is_reveal_checked);
  }
}

function MultiDisplayOrHide(class_name, reveal) {
  console.log("CLASS", class_name);
  var elements = document.getElementsByClassName(String(class_name));
  console.log("ELEMENTS", elements);
  for (var i = 0; i < elements.length; i++) {
    if (elements[i].classList.contains(reveal)) {
      elements[i].classList.remove("hidden");
    } else {
      elements[i].classList.add("hidden");
    }
  }
}

function DisplayOrHide(class_name, reveal) {
  let elements = document.getElementsByClassName(class_name);

  for (var i = 0; i < elements.length; i++) {
    if (reveal == "true") {
      elements[i].classList.remove("hidden");
    } else {
      elements[i].classList.add("hidden");
    }
  }
}

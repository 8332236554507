import { Controller } from "stimulus";

export default class extends Controller {
  connect() {}

  openEdit() {
    document.getElementById("year-field").classList.remove("hidden");
    console.log("HIT");
  }
}

import { Controller } from "stimulus";

export default class extends Controller {
  connect() {
    let individual_selectors = document.getElementsByClassName(
      "individual-selector"
    );
    for (var i = 0; i < individual_selectors.length; i++) {
      var selector = individual_selectors[i];
      var selected = selector.value;
      if (selected) {
        console.log("SELECTED", selector.value);
        //setSelectorField(selector);
      }
    }

    console.log("SELECTORS", individual_selectors);
    console.log("ARE THERE MULTIPLE?", multipleClients());
    let list_of_hidden = document.getElementsByClassName(
      "hidden-selected-client"
    );

    for (var i = 0; i < list_of_hidden.length; i++) {
      let element = list_of_hidden[i];

      let list_of_added_fields =
        element.parentNode.getElementsByClassName("tight");

      for (var j = 0; j < list_of_added_fields.length; j++) {
        console.log("to remove");
        list_of_added_fields[j].remove();
      }

      fetch(`/clients/${element.value}.json`)
        .then((response) => response.json())
        .then((json) => {
          console.log("INERT ADJ TO THIS: ", element.parentNode);
          setAdditionalFields(element.parentNode, json);
        });
    }
  }
  selected(e) {
    setSelectorField(e.target);
    multipleClients();
    console.log("ARE THERE MULTIPLE?", multipleClients());
  }
  mortgageSelect() {
    console.log("spuse select");
    showHideSpouseSelector();
  }
}

const setSelectorField = (e) => {
  fetch(`/clients/${e.value}.json`)
    .then((response) => response.json())
    .then((json) => setAdditionalFields(e, json));
  let label = e.options[e.selectedIndex].innerHTML;
  console.log("EVENT TARGET", e);
  e.insertAdjacentHTML(
    "beforebegin",
    `<div class="individual-entry client-entry" style="grid-area: individual">${label}</div>`
  );
  e.style.display = "none";
};
const multipleClients = () => {
  let client_list = document.getElementById("client-list");
  let nested_fields = client_list.getElementsByClassName("client-entry");
  let element = document.getElementById("joint-tenants-question");
  if (nested_fields.length > 1) {
    element.classList.remove("hidden");
    return true;
  } else {
    element.classList.add("hidden");
    return false;
  }
};
const setAdditionalFields = (e, json) => {
  if (json.type_of_client == "Individual") {
    console.log("INDIVIDUAL JSON", json);
    let id_section = "";
    let citizen = "";
    let married = "";
    if (json.individual.id_count >= 2) {
      id_section =
        "<div class='alert alert-success tight id-indicator'> Has at least two pieces of ID on file. </div>";
    } else {
      id_section = `<div class="alert alert-warning tight id-indicator"> Has ${json.individual.id_count} id document(s) on file. </div>`;
    }
    if (json.individual.canadian) {
      citizen =
        "<div class='alert alert-success tight'> Is Canadian citizen or permanent resident. </div>";
    } else {
      citizen = `<div class="alert alert-warning tight">Is not Canadian citizen or permanent resident (or status not entered). </div>`;
    }
    if (json.individual.marital_status == "Married") {
      married = "<div class='alert alert-success tight'> Is married ";
      if (json.individual.spouse != "") {
        married = `${married} to ${json.individual.spouse}.</div>`;
      } else {
        married = `${married} spuse not in system. </div>`;
      }
    } else if (json.individual.marital_status == "Single") {
      married = `<div class="alert alert-warning tight"> Is single </div>`;
    } else {
      married = `<div class="alert alert-warning tight"> Marital status not entered. </div>`;
    }

    e.insertAdjacentHTML(
      "beforebegin",
      `${id_section}${citizen}${married} <a class="small-link-text" href="/individuals/${json.individual.id}/edit" target="_blank">Open individual record in new tab</a>`
    );
  } else {
    e.insertAdjacentHTML(
      "beforebegin",
      `<div class="small">
    ${json.type_of_client}
   </div>`
    );
  }
  showHideSpouseSelector();
};

const showHideSpouseSelector = () => {
  let mortgage_selector = document
    .getElementById("mortgage-selector")
    .querySelectorAll('input[type="radio"]:checked');
  let mortgage =
    mortgage_selector.length > 0 ? mortgage_selector[0].value : null;
  console.log("Mortgage", mortgage);
  let spousal_selectors = document.getElementsByClassName(
    "client-spousal-fields"
  );
  for (var i = 0; i < spousal_selectors.length; i++) {
    let selector = spousal_selectors[i];
    let wrapper = selector.closest(".nested-fields");
    console.log("selector", selector.querySelector("select"));
    let id_statement = wrapper.getElementsByClassName("id-indicator");
    if (id_statement.length > 0 && mortgage === "true") {
      selector.classList.remove("hidden");
    } else {
      selector.querySelector("select").selectedIndex = 0;
      selector.classList.add("hidden");
    }
  }
};

import { Controller } from "stimulus";

export default class extends Controller {
  delete(event) {
    let e = event.target.parentNode;
    let image_id = e.dataset.image;
    let individual_id = e.dataset.individual;
    let url = `/delete_id_document/${individual_id}/${image_id}`;

    fetch(url).then((response) => {
      if (response.ok) {
        e.closest(".image-section").remove();
      }
    });
  }
}
